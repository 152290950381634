var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ScrollingDocument',_vm._b({staticClass:"pdf-document",attrs:{"enable-page-jump":true},on:{"page-jump":_vm.onPageJump,"pages-fetch":_vm.onPagesFetch,"pages-reset":_vm.fitAuto},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var page = ref.page;
var isPageFocused = ref.isPageFocused;
var isElementFocused = ref.isElementFocused;
return [_c('PDFPage',_vm._b({on:{"page-rendered":_vm.onPageRendered,"page-errored":_vm.onPageErrored,"page-focus":_vm.onPageFocused}},'PDFPage',{
      scale: _vm.scale,
      optimalScale: _vm.optimalScale,
      page: page,
      isPageFocused: isPageFocused,
      isElementFocused: isElementFocused,
      lastWidgetPageEvent: _vm.lastWidgetPageEvent
    },false))]}}])},'ScrollingDocument',{ pages: _vm.pages, pageCount: _vm.pageCount, onCurrentPage: _vm.onCurrentPage },false))}
var staticRenderFns = []

export { render, staticRenderFns }