var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pdf-viewer"},[_c('PDFData',{staticClass:"pdf-viewer__main",attrs:{"file":_vm.file},on:{"page-count":_vm.updatePageCount,"page-focus":_vm.updateCurrentPage,"page-rendered":_vm.onPageRendered,"document-errored":_vm.onDocumentErrored},scopedSlots:_vm._u([{key:"document",fn:function(ref){
var pages = ref.pages;
return [_c('PDFDocument',_vm._b({ref:"PDFDocument",staticClass:"pdf-viewer__document",on:{"scale-change":_vm.updateScale}},'PDFDocument',{
          pages: pages,
          scale: _vm.scale,
          optimalScale: _vm.optimalScale,
          fit: _vm.fit,
          onCurrentPage: _vm.onCurrentPage,
          pageCount: _vm.pageCount,
          lastWidgetPageEvent: _vm.lastWidgetPageEvent
        },false))]}}])}),_c('v-row',{staticClass:"mr-4",attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-chip',{staticClass:"ma-2"},[_vm._v(" "+_vm._s(_vm.onCurrentPage)+" / "+_vm._s(_vm.pageCount)+" ")]),_c('v-chip',{staticClass:"mt-2",on:{"click":_vm.goToLastPage}},[_c('v-tooltip',{attrs:{"bottom":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"16"}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-down")])]}}])},[_c('span',[_vm._v("Última página")])])],1)],1),(_vm.onCurrentPage > 1)?_c('v-chip',{staticClass:"mt-2 bottom-chip",on:{"click":_vm.goToFirstPage}},[_c('v-tooltip',{attrs:{"bottom":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"16"}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-up")])]}}],null,false,618284487)},[_c('span',[_vm._v("Primera página")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }