<template>
  <canvas v-visible.once="drawPage" v-bind="canvasAttrs"></canvas>
</template>

<script>
import { fabric } from "fabric";
import { PIXEL_RATIO } from "@/utils/constants";
import visible from "@/directives/visible";
import { bus } from "../../main";

const WIDGET_DEFAULT_WIDTH = 120;
const WIDGET_DEFAULT_HEIGHT = 60;

export default {
  name: "PDFPage",

  created() {
    // PDFPageProxy#getViewport
    // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
    this.viewport = this.page.getViewport(this.optimalScale);

    bus.$on("remove-recipient", payload => {
      if (payload.pageNumber == this.page.pageNumber) {
        var objectFromCanvas = this.getObjectFromCanvasById(
          payload.recipientEmail
        );
        this.fabricCanvas.remove(objectFromCanvas);
      }
    });
    bus.$on("repositionate-recipient", payload => {
      if (payload.pageNumber == this.page.pageNumber) {
        this.recreateFabricWidget(payload);
      }
    });
  },

  beforeDestroy() {
    bus.$off("repositionate-recipient");
    bus.$off("remove-recipient");
    this.destroyPage(this.page);
  },

  data() {
    return {
      fabricWidgets: [],
      fabricCanvas: undefined
    };
  },

  props: {
    page: {
      type: Object, // instance of PDFPageProxy returned from pdf.getPage
      required: true
    },
    scale: {
      type: Number,
      required: true
    },
    optimalScale: {
      type: Number,
      required: true
    },
    isPageFocused: {
      type: Boolean,
      default: false
    },
    isElementFocused: {
      type: Boolean,
      default: false
    },
    lastWidgetPageEvent: {
      type: Object,
      required: false
    }
  },

  directives: {
    visible
  },

  computed: {
    actualSizeViewport() {
      return this.viewport.clone({ scale: this.scale });
    },

    canvasStyle() {
      const {
        width: actualSizeWidth,
        height: actualSizeHeight
      } = this.actualSizeViewport;
      const [pixelWidth, pixelHeight] = [
        actualSizeWidth,
        actualSizeHeight
      ].map(dim => Math.ceil(dim / PIXEL_RATIO));
      return `width: ${pixelWidth}px; height: ${pixelHeight}px;`;
    },

    canvasAttrs() {
      let { width, height } = this.viewport;
      [width, height] = [width, height].map(dim => Math.ceil(dim));
      const style = this.canvasStyle;

      return {
        width,
        height,
        style,
        class: "pdf-page box-shadow"
      };
    },

    pageNumber() {
      return this.page.pageNumber;
    }
  },

  methods: {
    focusPage() {
      if (this.isPageFocused) return;
      this.$emit("page-focus", this.pageNumber);
    },

    getObjectFromCanvasById(id) {
      var canvasObjectIndex = this.fabricCanvas
        .getObjects()
        .findIndex(item => item.id === id);
      if (canvasObjectIndex !== -1) {
        return this.fabricCanvas.getObjects()[canvasObjectIndex];
      }
      return null;
    },

    recreateFabricWidget(payload) {
      var objectFromCanvas = this.getObjectFromCanvasById(
        payload.recipientEmail
      );
      if (objectFromCanvas !== null) {
        return;
      }
      let widgetWidth = WIDGET_DEFAULT_WIDTH;
      let widgetHeight = WIDGET_DEFAULT_HEIGHT;
      if (payload.exists) {
        widgetWidth = payload.width || WIDGET_DEFAULT_WIDTH;
        widgetHeight = payload.height || WIDGET_DEFAULT_HEIGHT;
      }

      return new Promise(resolve => {
        var rect = new fabric.Rect({
          height: widgetHeight,
          width: widgetWidth,
          fill: "#eeeeeebb",
          stroke: "black",
          originX: "center",
          originY: "center",
          strokeWidth: 1
        });

        var self = this;

        fabric.Image.fromURL(".../../../../assets/close.png", function(myImg) {
          var img1 = myImg.set({
            left: -Math.abs(widgetWidth / 2),
            top: -Math.abs(widgetHeight / 2),
            originX: "center",
            originY: "center",
            hoverCursor: "pointer",
            id: "delete-btn-" + payload.recipientEmail,
            scaleX: 0.2,
            scaleY: 0.2
          });

          var text = new fabric.Text(payload.recipientName.substring(0, 15), {
            fontSize: "14",
            fontFamily: "Exo,Inter",
            originX: "center",
            originY: "center"
          });

          var widget = new fabric.Group([rect, text, img1], {
            lockScalingFlip: true,
            subTargetCheck: true,
            id: payload.recipientEmail
          });

          self.redrawWidget(widget, payload);
          img1.on("mousedown", self.onWidgetRemoved);
          resolve();
        });
      });
    },

    redrawWidget(widget, payload) {
      let widgetTop;
      if (payload.flow) {
        widgetTop = payload.y;
      } else {
        widgetTop =
          this.fabricCanvas.height -
          payload.y -
          payload.height * payload.scaleY;
      }

      widget.left = payload.x;
      widget.top = widgetTop;
      widget.scaleX = payload.scaleX;
      widget.scaleY = payload.scaleY;
      this.fabricCanvas.add(widget);

      if (payload.flow) {
        bus.$emit("recipient-widget-modified", {
          scaleX: payload.scaleX,
          scaleY: payload.scaleY,
          y: this.fabricCanvas.height - payload.height,
          x: 0,
          height: payload.height,
          width: payload.width
        });
      }
    },

    removeWidget(widget) {
      const objectFromCanvas = this.getObjectFromCanvasById(widget.id);
      this.fabricCanvas.remove(objectFromCanvas);
    },

    drawPage() {
      if (this.renderTask) return;
      const { viewport } = this;
      const canvasContext = this.$el.getContext("2d");
      const renderContext = { canvasContext, viewport };

      // PDFPageProxy#render
      // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
      this.renderTask = this.page.render(renderContext);
      this.renderTask
        .then(() => {
          //Need to set background pdf image to have fabric items in foreground.
          var bg = this.$el.toDataURL("image/png");
          this.fabricCanvas = new fabric.Canvas(this.$el, {
            selection: false,
            allowTouchScrolling: true
          });
          this.fabricCanvas.setBackgroundImage(
            bg,
            this.fabricCanvas.renderAll.bind(this.fabricCanvas)
          );
          this.fabricCanvas.on("object:moving", this.onWidgetMoving);
          this.fabricCanvas.on("object:scaling", this.onWidgetScaling);
          this.fabricCanvas.on("object:modified", this.onWidgetModified);
          this.$emit("page-rendered", this.pageNumber);
        })
        .catch(response => {
          this.destroyRenderTask();
          this.$emit("page-errored", {
            response,
            page: this.page,
            text: `Failed to render page ${this.pageNumber}`
          });
        });
    },

    onWidgetMoving(e) {
      if (e.target.top < 0) {
        e.target.top = 0;
      }
      if (e.target.left < 0) {
        e.target.left = 0;
      }
      if (
        e.target.left + e.target.width * e.target.scaleX >
        this.fabricCanvas.width
      ) {
        e.target.left =
          this.fabricCanvas.width - e.target.width * e.target.scaleX;
      }
      if (
        e.target.top + e.target.height * e.target.scaleY >
        this.fabricCanvas.height
      ) {
        e.target.top =
          this.fabricCanvas.height - e.target.height * e.target.scaleY;
      }
    },

    onWidgetModified(e) {
      var width = e.target.width;
      var height = e.target.height;
      var x = e.target.left;
      var y =
        e.target.canvas.height -
        e.target.top -
        e.target.height * e.target.scaleY;

      bus.$emit("recipient-widget-modified", {
        pageNumber: this.page.pageNumber,
        recipientEmail: e.target.id,
        scaleX: e.target.scaleX,
        scaleY: e.target.scaleY,
        y: y,
        x: x,
        height: height,
        width: width
      });
    },

    onWidgetScaling(e) {
      if (
        e.target.width * e.target.scaleX < 100 ||
        e.target.height * e.target.scaleY < 50
      ) {
        e.target.scaleX = 100 / e.target.width;
        e.target.scaleY = 50 / e.target.height;
      }
      if (
        e.target.left + e.target.width * e.target.scaleX >
        this.fabricCanvas.width
      ) {
        e.target.scaleX =
          this.fabricCanvas.width / (e.target.width + e.target.left);
        e.target.scaleY = e.target.scaleX;
      }

      if (
        e.target.top + e.target.height * e.target.scaleY >
        this.fabricCanvas.height
      ) {
        e.target.scaleY =
          this.fabricCanvas.height / (e.target.height + e.target.top);
        e.target.scaleX = e.target.scaleY;
      }
    },

    onWidgetRemoved(e) {
      var id = e.target.id;
      var canvasObjectById = this.fabricCanvas
        .getObjects()
        .find(item => item.id === id);
      if (canvasObjectById !== undefined) {
        this.fabricCanvas.remove(canvasObjectById);
        bus.$emit("recipient-widget-removed", {
          pageNumber: this.page.pageNumber,
          recipientEmail: id
        });
      }
    },

    updateVisibility() {
      this.$parent.$emit("update-visibility");
    },

    destroyPage(page) {
      // PDFPageProxy#_destroy
      // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
      if (page) page._destroy();

      this.destroyRenderTask();
    },

    destroyRenderTask() {
      if (!this.renderTask) return;

      // RenderTask#cancel
      // https://mozilla.github.io/pdf.js/api/draft/RenderTask.html
      this.renderTask.cancel();
      delete this.renderTask;
    },

    //LEGACY METHODS FOR SFA:
    drawWidgetLegacy(widget) {
      var timeout = 200;
      if (this.fabricCanvas === undefined) {
        this.drawPage();
        timeout = 1500;
      }
      this.addWidgetWithTimeoutLegacy(widget, timeout);
    },

    addWidgetWithTimeoutLegacy(widget, timeout) {
      setTimeout(() => {
        //center widget on screen
        widget.left = this.fabricCanvas.width / 2 - widget.width / 2;
        widget.top = this.fabricCanvas.height / 2 - widget.height / 2;
        this.fabricCanvas.add(widget);
      }, timeout);
    }
  },

  watch: {
    scale: "updateVisibility",

    lastWidgetPageEvent(widgetPageEvent) {
      if (widgetPageEvent.page === this.page.pageNumber) {
        if (widgetPageEvent.action === "draw") {
          this.drawWidgetLegacy(widgetPageEvent.widget);
        } else if (widgetPageEvent.action === "delete") {
          this.removeWidget(widgetPageEvent.widget);
        }
      }
    },

    page(_newPage, oldPage) {
      this.destroyPage(oldPage);
    },

    isElementFocused(isElementFocused) {
      if (isElementFocused) this.focusPage();
    }
  }
};
</script>
<style>
.pdf-page {
  display: block;
  margin: 0 auto;
}
.canvas-container {
  margin: 0 auto;
}
canvas {
  border: 1px solid #aaa;
}
</style>
