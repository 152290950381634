<template>
  <div class="pdf-viewer">
    <PDFData
      class="pdf-viewer__main"
      :file="file"
      @page-count="updatePageCount"
      @page-focus="updateCurrentPage"
      @page-rendered="onPageRendered"
      @document-errored="onDocumentErrored"
    >
      <template v-slot:document="{ pages }">
        <PDFDocument
          ref="PDFDocument"
          class="pdf-viewer__document"
          v-bind="{
            pages,
            scale,
            optimalScale,
            fit,
            onCurrentPage,
            pageCount,
            lastWidgetPageEvent
          }"
          @scale-change="updateScale"
        />
      </template>
    </PDFData>

    <v-row class="mr-4" no-gutters>
      <v-spacer></v-spacer
      ><v-chip class="ma-2"> {{ onCurrentPage }} / {{ pageCount }} </v-chip>
      <v-chip class="mt-2" @click="goToLastPage">
        <v-tooltip bottom min-width="120">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" size="16">mdi-arrow-down</v-icon>
          </template>
          <span>Última página</span>
        </v-tooltip>
      </v-chip>
    </v-row>

    <v-chip
      class="mt-2 bottom-chip"
      @click="goToFirstPage"
      v-if="onCurrentPage > 1"
    >
      <v-tooltip bottom min-width="120">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" size="16">mdi-arrow-up</v-icon>
        </template>
        <span>Primera página</span>
      </v-tooltip>
    </v-chip>
  </div>
</template>

<script>
import PDFDocument from "./PDFDocument";
import PDFData from "./PDFData";

function floor(value, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.floor(value * multiplier) / multiplier;
}

export default {
  name: "PDFViewer",

  components: {
    PDFDocument,
    PDFData
  },

  props: {
    file: File,
    lastWidgetPageEvent: {
      type: Object,
      required: false
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1
    }
  },

  data() {
    return {
      scale: undefined,
      optimalScale: 1,
      fit: undefined,
      pageCount: undefined,
      onCurrentPage: 1
    };
  },
  mounted() {
    this.onCurrentPage = this.currentPage;
  },
  methods: {
    onPageRendered(pageNumber) {
      this.$emit("page-rendered", pageNumber);
    },

    onDocumentErrored(e) {
      this.$emit("document-errored", e);
    },

    updateScale({ scale, isOptimal = false }) {
      const roundedScale = floor(scale, 2);
      if (isOptimal) this.optimalScale = roundedScale;
      this.scale = roundedScale;
    },

    updateFit(fit) {
      this.fit = fit;
    },

    updatePageCount(pageCount) {
      this.pageCount = pageCount;
    },

    updateCurrentPage(pageNumber) {
      this.onCurrentPage = pageNumber;
      this.$emit("page-focus", pageNumber);
    },
    goToLastPage() {
      this.updateCurrentPage(this.pageCount);
      //TODO:
      setTimeout(() => {
        this.updateCurrentPage(this.pageCount);
      }, 500);
    },
    goToFirstPage() {
      this.updateCurrentPage(1);
    }
  }
};
</script>
<style>
.bottom-chip {
  position: absolute;
  bottom: 1.7rem;
  right: 1.7rem;
}
</style>
